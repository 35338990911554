import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import { mediaQuery } from 'utils/style'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'
import { mathMod } from 'ramda'
import Link from 'components/Link'
import FocusArea, { FocusContainer } from 'components/FocusArea'

const Carousel = virtualize(SwipeableViews)

const ResponsiveCarousel = styled(props => (
  <Carousel slideRenderer={() => {}} {...props} />
))`
  padding-right: ${({ isMultiSlides }) => (isMultiSlides ? '7.07vw' : '0')};
  ${mediaQuery()({ mobile: 'padding-right: 0;' })}
`

const Container = styled.div`
  &&& {
    width: 100%;
    max-width: unset;
    padding: 0;
    margin: 0;
    position: relative;
    ${mediaQuery()({
    mobile: 'height: calc(50vh);',
    tablet: 'height: 430px;',
    desktop: 'height: 530px',
    max: 'height: 630px;'
  })}
  }
`

const SlideControl = styled.div`
  width: 7.07vw;
  position: absolute;
  top: 0;
  ${({ back }) => (back ? 'left: 0;' : 'right: 0;')}
  height: 100%;
  z-index: 1;
  cursor: pointer;
  ::after {
    content: ' ';
    position: absolute;
    height: 3vw;
    width: 3vw;
    border-color: white;
    border-style: solid;
    border-top-width: 2px;
    border-left-width: 2px;
    border-bottom: 0;
    border-right: 0;
    left: ${({ back }) => (back ? 70 : 30)}%;
    top: 50%;
    transform: translate(-50%, -50%)
      rotate(${({ back }) => (back ? '-45deg' : '135deg')});
  }
  :hover::after {
    border-color: ${({ theme }) => theme.colors.base.orange};
  }
`

const DotContainer = styled.div``

const Dot = styled.div`
  border-radius: 15px;
  border: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  width: 25px;
  height: 25px;
  margin: 10px;
`

const Dots = styled.div`
  display: none;
  flex-direction: row;
  align-self: flex-end;
  justify-content: center;
  ${mediaQuery()({
    mobile: 'display: flex;'
  })}
  > ${DotContainer} {
    :nth-child(${({ current }) => current + 1}) {
      > ${Dot} {
        background-color: ${({ theme }) => theme.colors.base.lightGray};
      }
    }
  }
`

const SlideContainer = styled.div`
  display: flex;
  ${({ isMultiSlides }) => mediaQuery()({
    mobile: `height: calc(${isMultiSlides ? '50vh - 50px' : '50vh'}); flex-direction: column;`,
    tablet: 'height: 430px;',
    desktop: 'height: 530px;',
    max: 'height: 630px;'
  })}
`

const BigImage = styled.div`
  overflow: hidden;
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  position: relative;
  flex-basis: 50vw;
  width: 50vw;
  ${mediaQuery()({ mobile: 'flex: 1; width: 100vw;' })}
`

const Rest = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${mediaQuery()({
    mobile: 'flex-grow: 0; flex-shrink: 0; flex-basis: auto; z-index: 1;'
  })}
`

const SmallImage = styled.div`
  position: relative;
  flex: 1;
  ${mediaQuery()({ mobile: 'display: none;' })};
`

const Title = styled.div`
  flex-basis: 38.2%;
  display: flex;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  span, h1 {
    font-size: 40px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.base.gray};
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    transition: padding 0.3s ease-in-out;
    margin-bottom: 0;
  }
  :hover > span {
    padding: 25px;
  }
  :hover > h1 {
    padding: 25px;
  }
  ${mediaQuery()({ mobile: 'align-items: flex-start; span, h1 { font-size: 36px; padding: 25px; }' })}
`

function Slide ({ data, isMultiSlides, idx }) {
  const {
    link,
    bigImage,
    title1,
    title2,
    bigImageAlt,
    bigImageTitle,
    bigImageDescription,
    smallImage,
    smallImageAlt,
    smallImageTitle,
    smallImageDescription,
    focusX1 = 10,
    focusY1 = 10,
    focusX2 = 90,
    focusY2 = 90,
    bigImagePositionX,
    bigImagePositionY,
    smallImagePositionX,
    smallImagePositionY,
    aspectRatio: aspectRatioEditable
  } = data
  const aspectRatio = bigImage?.localFile?.childImageSharp?.fluid?.aspectRatio || aspectRatioEditable || 2.4
  const TitleTag = isMultiSlides ? 'span' : 'h1'
  return (
    <Link link={link}>
      <SlideContainer isMultiSlides={isMultiSlides}>
        <BigImage>
          <Image
            critical={!idx}
            max={`&& > .gatsby-image-wrapper, > img, ${FocusContainer} {
              -ms-writing-mode: lr-tb;
              transform: translateX(25vw) translateX(-${
                bigImagePositionX || 50
              }%);
              transform: translateX(max(calc(1px + 50vw - 100%), min(0px, calc(25vw - ${
                bigImagePositionX || 50
              }%))));
              -ms-transform: ${bigImagePositionX < 34 ? 'translateY(0px)' : bigImagePositionX > 65 ? 'translateY(50vw) translateY(-100%)' : `translateY(25vw) translateY(-${bigImagePositionX || 50}%)`};
            }
            height:100%;
            width:0;
            padding-right: ${aspectRatio * 100}%;
            `}
            mobile={`&& > .gatsby-image-wrapper, > img, ${FocusContainer} {
              transform: translateX(50vw) translateX(-${
                bigImagePositionX || 50
              }%);
              transform: translateX(max(calc(100vw - 100%), min(0px, calc(50vw - ${
                bigImagePositionX || 50
              }%))));
            }`}
            absolute
            src={bigImage}
            alt={bigImageAlt}
            title={bigImageTitle}
            description={bigImageDescription}
            objectPosition={[bigImagePositionX, bigImagePositionY]
              .filter(Boolean)
              .map(x => `${x}%`)
              .join(' ')}
          >
            <FocusArea
              left={focusX1}
              top={focusY1}
              right={100 - (Number(focusX2) || 0)}
              bottom={100 - (Number(focusY2) || 0)}
            />
          </Image>
        </BigImage>
        <Rest>
          <SmallImage>
            <Image
              critical={!idx}
              background
              src={smallImage}
              alt={smallImageAlt}
              title={smallImageTitle}
              description={smallImageDescription}
              objectPosition={[smallImagePositionX, smallImagePositionY]
                .filter(Boolean)
                .map(x => `${x}%`)
                .join(' ')}
            />
          </SmallImage>
          <Title>
            <TitleTag>
              {title1}
              {title2 && <br />}
              {title2}
            </TitleTag>
          </Title>
        </Rest>
      </SlideContainer>
    </Link>
  )
}

export default function StoryblokCarousel ({ data }) {
  const [index, setIndex] = useState(0)
  const isMultiSlides = useMemo(() => data?.slides?.length > 1, [data])
  return (
    <Container>
      {isMultiSlides && (
        <SlideControl back onClick={() => setIndex(state => state - 1)} />
      )}
      <ResponsiveCarousel
        isMultiSlides={isMultiSlides}
        index={index}
        onChangeIndex={setIndex}
        slideStyle={{ overflow: 'hidden' }}
        slideRenderer={({ index: idx, key }) => (
          <Slide
            data={data.slides[mathMod(idx, data.slides.length)]}
            isMultiSlides={isMultiSlides}
            idx={mathMod(idx, data.slides.length)}
            key={key}
          />
        )}
      />
      {isMultiSlides && (
        <SlideControl onClick={() => setIndex(state => state + 1)} />
      )}
      {isMultiSlides && (
      <Dots current={mathMod(index, data.slides.length)}>
        {data.slides.map((slide, idx) => (
          <DotContainer key={slide.id} onClick={() => setIndex(idx)}>
            <Dot />
          </DotContainer>
        ))}
      </Dots>
      )}
    </Container>
  )
}
