import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Link from 'components/Link'
import { ModalContext } from 'components/Modal'

const Text = styled.p`
  margin-right: 2rem;
  position: relative;
  :after {
    content: ' ';
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    left: 100%;
    bottom: 0;
    transform: translateY(-50%) rotate(45deg);
    transition: margin-left 0.3s ease-in-out;
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  transition: background 0.3s ease-in-out;
  > * {
    transition: filter 0.3s ease-in-out;
  }
  :hover {
    background: ${({ theme }) => theme.colors.base.darkBlue};
    ${Text}:after {
      margin-left: 1em;
    }
    > * {
      filter: brightness(0) invert(1);
    }
  }
  
`

const ContentImage = styled.div`
  position: relative;
  flex: 1;
  margin-bottom:16px;  
`

export default function StoryblokTileCallToAction ({
  data,
  linkProps
}) {
  const modal = useContext(ModalContext)
  const Title = modal != null ? 'h4' : 'h3'
  return (
    <Wrapper
      as={Link}
      {...linkProps || {
        link: data.link, gtmEvent: data.gtmEvent, productModalType: data.extendedProductModalLink, productModalGroup: data.extendedProductModalGroup
      }}
    >
      <ContentImage>
        <Image
          style={{ position: 'absolute' }}
          src={data.image}
          alt={data.imageAlt}
          title={data.imageTitle}
          description={data.imageDescription}
          objectPosition="left center"
        />
      </ContentImage>
      <Title>{data.title}</Title>
      <Text>{`${data.text}`}</Text>
    </Wrapper>
  )
}
