import styled from 'styled-components'

export default styled.button`
  display: block;
  border: 1px solid transparent;
  color: white;
  background-color: ${props => props.theme.colors.base.orange};
  width: fit-content;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 32px;
  line-height: 1em;
  text-transform: uppercase;
  :hover {
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
  }
  :disabled,
  [disabled] {
    background-color: ${({ theme }) => theme.colors.gray.color};
  }
`
