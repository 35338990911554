import React, { useState } from 'react'
import styled from 'styled-components'
import RemarkRenderer, {
  Container as RemarkContainer
} from 'components/RemarkRenderer'
import Link from 'components/Link'
import Image from 'components/Image'
import { baseColors } from 'utils/theme'
import { mediaQuery } from 'utils/style'
import StoryblokQuickAction from 'components/StoryblokQuickAction'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Box } from '@material-ui/core'

const Container = styled.div`
  &&& {
    width: 100%;
    max-width: unset;
    margin-bottom: 0;
    ${mediaQuery(true)}
    a {
      color: ${baseColors.lightGray};
    }
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${baseColors.darkGray};
  color: ${baseColors.lightGray};
  overflow: hidden;
  position: relative;

  * {
    transition: all 80ms ease-in-out;
  }

  ${RemarkContainer} p,
  ${RemarkContainer} p strong {
    font-size: 0.8rem;
    line-height: 1.5rem;
      ${mediaQuery()({
    desktop: 'margin-top: -0.2rem;'
  })}
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 auto;
  ${mediaQuery()({
    desktop: 'flex-direction: row;',
    mobile: 'flex-direction: column;'
  })}

  > * {
    width: 100%;
    padding: 1rem;
    margin: 0;
    flex: 1 1 25%;
  }
`

const LogoWrapper = styled(Box)`
  padding-top: 0;
  padding-left: 0;

  ${mediaQuery()({
    mobile: 'padding-right: 5rem;'
  })}

  > * {
    width: 100%;
  }
`

const NavItem = styled.div`
  font-size: 12px;
  padding-right: 2em;
  position: relative;

  > a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      filter: brightness(0.8);
    }
  }
`

const SocialLinks = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;

  ${mediaQuery()({
    desktop: 'justify-content: flex-end;',
    mobile: 'justify-content: flex-start;'
  })}
`

const SocialMediaLink = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border: 2px solid;
  border-color: ${baseColors.lightGray};
  border-radius: 50%;
  padding: 0.4rem;

  &:hover {
    filter: brightness(0.8);
  }
`

const LinksWrapper = styled(Box)`
  display: flex;
  flex-basis: 50%;
  ${mediaQuery()({
    desktop: 'flex-direction: row;',
    mobile: 'flex-direction: column-reverse;'
  })}

  > * {
    ${mediaQuery()({
    mobile: 'padding: 1rem 0;'
  })}
  }
`

const MobileQuickActionControl = styled.div`
  width: 60px;
  height: 60px;
  pointer-events: all;
  padding: 18px;
  -ms-flex-preferred-size: 24px;
  flex-shrink: 0;
  display: none;
  background-color: ${({ theme }) => theme.colors.base.orange};
  margin-left: auto;
  ${mediaQuery()({ tablet: 'display: flex;' })}
`

const NavLinksWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex: 2;
  > ${NavItem} {
    flex: 1 1 50%;
  }
`

const QuickActionContainer = styled.div`
  position: fixed;
  pointer-events: none;
  right: 0;
  bottom: 33vh;
  z-index: 700;
  transform: translateY(33%);
`

export default function Footer ({ data }) {
  const [open, setOpen] = useState(false)
  if (!data?.links) return null
  return (
    <Container root>
      <Wrapper>
        <LogoWrapper>
          <Image alt="logo" src={data.logo} max="width: 25%;" />
        </LogoWrapper>
        <RemarkRenderer
          background="black"
          ast={data.address?.childMarkdownRemark?.htmlAst}
        />

        <LinksWrapper>
          <NavLinksWrapper>
            {data.links.map(item => (
              <NavItem key={item.id}>
                <Link link={item.link}>{item.label}</Link>
              </NavItem>
            ))}
          </NavLinksWrapper>

          <SocialLinks>
            {data.socialMediaLinks?.map(item => (
              <Link key={item.id} link={item.link}>
                <SocialMediaLink>
                  <Image src={item.icon} alt={item.alt} title={item.title} />
                </SocialMediaLink>
              </Link>
            ))}
          </SocialLinks>
        </LinksWrapper>
      </Wrapper>

      {/* <CookieDisclaimer
        text={data.cookieDisclaimerText}
        buttonLabel={data.cookieButtonLabel}
      /> */}

      <QuickActionContainer>
        {data.quickActions.map(qa => (
          <StoryblokQuickAction data={qa} open={open} />
        ))}
        <MobileQuickActionControl onClick={() => setOpen(o => !o)}>
          <MoreIcon />
        </MobileQuickActionControl>
      </QuickActionContainer>
    </Container>
  )
}
