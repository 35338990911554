import React, { useState } from 'react'
import styled from 'styled-components'
import Spinner from 'components/Spinner'
import StoryblokComponents from 'components/StoryblokComponents'

const Container = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > * {
      margin-bottom: 64px;
      width: 100%;
    }
  }
`

export default function CustomModal (props) {
  const [loaded, setLoaded] = useState(false)
  return (
    <Container>
      {!loaded && <Spinner />}
      <StoryblokComponents
        onLoaded={() => setLoaded(true)}
        components={props?.data?.component?.body}
        inheritBg="modal"
      />
    </Container>
  )
}
