import React from 'react'
import styled from 'styled-components'
import { mediaQuery } from 'utils/style'

const sizes = {
  mobile: {
    focus1: `height: 1.5rem;
    width: 1.5rem;
    border-left-width: 0.6rem;
    border-bottom-width: 0.6rem;`,
    focus2: `height: 1.5rem;
    width: 1.5rem;
    border-right-width: 0.6rem;
    border-top-width: 0.6rem;`
  },
  tablet: {
    focus1: `height: 2rem;
    width: 2rem;
    border-left-width: 0.8rem;
    border-bottom-width: 0.8rem;`,
    focus2: `height: 2rem;
    width: 2rem;
    border-right-width: 0.8rem;
    border-top-width: 0.8rem;`
  },
  desktop: {
    focus1: `height: 3.125rem;
    width: 3.125rem;
    border-left-width: 1.25rem;
    border-bottom-width: 1.25rem;`,
    focus2: `height: 3.125rem;
    width: 3.125rem;
    border-right-width: 1.25rem;
    border-top-width: 1.25rem;`
  },
  max: {
    focus1: `height: 3.125rem;
    width: 3.125rem;
    border-left-width: 1.25rem;
    border-bottom-width: 1.25rem;`,
    focus2: `height: 3.125rem;
    width: 3.125rem;
    border-right-width: 1.25rem;
    border-top-width: 1.25rem;`
  }
}

const Focus1 = styled.div`
  ${mediaQuery()({
    media: d => sizes[d].focus1
  })};
  border-right: 0;
  border-top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue.color};
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: left, bottom;
`

const Focus2 = styled.div`
  ${mediaQuery()({
    media: d => sizes[d].focus2
  })};
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue.color};
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: right, top;
`

export const FocusContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  :hover ${Focus1} {
    left: -5%;
    bottom: -5%;
  }
  :hover ${Focus2} {
    top: -5%;
    right: -5%;
  }
`
const FocusArea = styled.div`
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  bottom: ${({ bottom }) => bottom}%;
  right: ${({ right }) => right}%;
`

export default function Focus (props) {
  return (
    <FocusContainer>
      <FocusArea {...props}>
        <Focus1 />
        <Focus2 />
      </FocusArea>
    </FocusContainer>
  )
}
