/**
 *
 * Spinner
 *
 */

import React from 'react'
import styled from 'styled-components'
import './spinkit.scss'

function UnstyledSpinner (props) {
  const { className } = props

  return (
    <div className={className}>
      <div className="sk-three-bounce">
        <div className="sk-child sk-bounce1" />
        <div className="sk-child sk-bounce2" />
        <div className="sk-child sk-bounce3" />
      </div>
    </div>
  )
}

const Spinner = styled(UnstyledSpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isFullscreen }) => isFullscreen && `position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    z-index: 2000;`};
`

Spinner.propTypes = {}

export default Spinner
