export const color = (propName, color = 'color') => props =>
  props?.theme?.colors?.[props[propName]]?.[color]
export const image = propName => (props) => {
  const prop = props?.[propName]

  return typeof prop === 'string'
    ? prop
    : prop?.localFile?.childImageSharp?.original?.src
}

export const mediaQuery = (setWidth = false) => ({
  media = () => '',
  mobile = '',
  desktop = '',
  max = '',
  tablet = '',
  extraWide,
  bg,
  previous,
  next
}) => `
  ${setWidth ? `width: 100%; margin-top: 0; margin-bottom: 0; padding: ${(bg || 'white') === previous ? 24 : 48}px calc(50vw - ${extraWide ? 770 : 510}px) ${(bg || 'white') === next ? 24 : 48}px;` : ''}
  ${media('max')}
  ${max}

  @media (max-width: 1640px) {
    ${setWidth ? `padding: ${(bg || 'white') === previous ? 24 : 48}px calc(50vw - ${extraWide ? 540 : 410}px) ${(bg || 'white') === next ? 24 : 48}px;` : ''}
    ${media('desktop')}
    ${desktop}
  }
  @media (max-width: 1080px) {
    ${setWidth ? `padding: ${(bg || 'white') === previous ? 24 : 48}px calc(50vw - 360px) ${(bg || 'white') === next ? 24 : 48}px;` : ''}
    ${media('tablet')}
    ${tablet}
  }

  ${setWidth ? `@media (max-width: 720px) {
    padding: ${(bg || 'white') === previous ? 16 : 32}px ${extraWide ? 0 : 16}px ${(bg || 'white') === previous ? 16 : 32}px;
  }` : ''}

  @media (max-width: 480px) {
    ${media('mobile')}
    ${mobile}
  }
`
