import React from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import useProductContext from 'hooks/useProductContext'
import { mediaQuery } from 'utils/style'

const HighlightContainer = styled.div`
  height: 30rem;
  width: 100%;
  display: flex;
  margin-top: 64px;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'flex-direction:column;' : '')
  })}
`
const Highlight = styled.div`
  padding-left: 1rem;
  flex: 1;
`

export default function ProductHighlightModal ({ product, ...props }) {
  const { selected, select } = useProductContext(product, 'highlight')
  const highlights = product?.component?.highlights

  const highlight = selected
    ? highlights?.find(hl => hl.highlight?.[0]?.id === selected)
    : highlights?.[0]

  return (
    <>
      <h1>{product?.component?.highlightsTitle}</h1>
      <div className="subline" style={{ paddingBottom: 32 }}>
        {product?.component?.highlightSubtitle}
      </div>
      <RemarkRenderer
        ast={
          product?.component?.highlightDescription?.childMarkdownRemark?.htmlAst
        }
      />
      <HighlightContainer>
        <Carousel
          init={
            (highlights?.findIndex(hl => hl.highlight?.[0]?.id === selected)
              + 1 || 1) - 1
          }
          onSlide={idx => select(highlights[idx].highlight[0]?.id)}
          media={device =>
            (device === 'mobile' ? 'width: 100%;' : 'width: 50%; height: 100%;')
          }
          gallery={{
            titles: highlights?.map(hl => hl.highlight[0]?.component.title)
          }}
          infinite
          hideBackside
        >
          {highlights?.map(hl => (
            <Image
              title={hl.highlight[0]?.component.imageTitle}
              src={hl.highlight[0]?.component.image}
              alt={hl.highlight[0]?.component.imageAlt}
            />
          ))}
        </Carousel>
        <Highlight>
          <h3>{highlight?.highlight?.[0]?.component?.title}</h3>
          <RemarkRenderer
            ast={
              highlight?.highlight?.[0]?.component?.description
                ?.childMarkdownRemark?.htmlAst
            }
          />
        </Highlight>
      </HighlightContainer>
    </>
  )
}
