import React from 'react'
import RemarkRenderer from 'components/RemarkRenderer'
import Container from 'components/Container'

export default ({ data, inheritBg, ...props }) => (
  <Container id={data.hash} max="display: flex;" {...props}>
    <RemarkRenderer
      background={inheritBg}
      ast={data.content?.childMarkdownRemark?.htmlAst}
      clamp={data.clampHeight}
      style={{ width: '100%', textAlign: data.textAlign }}
    />
  </Container>
)
