import React, {
  useRef, useEffect, useReducer, useContext, useCallback, useState
} from 'react'
import { ModalContext } from 'components/Modal'
import { RemarkContext } from 'components/RemarkRenderer'
import styled from 'styled-components'


const TableEl = styled.table`
    min-width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.base.darkGray};
    border-collapse: collapse;
    transition: transform 0.3s ease-in-out;
    transform: translateX(${({ x }) => x}px);
    thead tr {
        border-bottom: 1px solid ${({ theme }) => theme.colors.base.darkGray};
    }
    th, td {
        padding: 6px 18px;
    }
`
const Clamper = styled.div`
    width: 100%;
    overflow: hidden;
`
const Container = styled.div`
    width: calc(100% - 64px);
    margin: 0 32px;
    overflow: visible;
    position: relative;
`

const Arrow = styled.div`  
    height: 1rem;
    width: 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.base.orange};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
`

const ArrowContainer = styled.div`
  padding: 15px;
  position: absolute;
  bottom: 50%;
  ${({ direction }) => (direction)}: -30px;
  transition: transform 0.3s ease-in-out;
  transform: translateY(-50%);
  ${Arrow} {
      transform: rotate(${({ direction }) => (direction === 'left' ? 45 : -135)}deg)
  }
`

export default function Table (props) {
  const ref = useRef()
  const modal = useContext(ModalContext)
  const background = useContext(RemarkContext)
  const [state, setState] = useReducer((s, action) => {
    const result = { ...s, ...action.init }
    if (action.scrollRight && result.right) result.idx += 1
    if (action.scrollLeft && result.left) result.idx -= 1
    result.left = result.idx > 0
    result.x = result.cols.reduce((acc, val, i) => ((i < result.idx) ? acc + val : acc), 0)
    result.right = result.x < result.total - result.clamped
    if (!result.right) result.x = result.total - result.clamped
    return result
  }, { left: false, right: false })

  useEffect(() => {
    const init = () => {
      if (ref.current.children[0].clientWidth > ref.current.clientWidth) {
        const ths = ref.current.children[0].querySelectorAll('th')
        const cols = []
        for (const th of ths) {
          cols.push(th.clientWidth)
        }
        setState({
          init: {
            total: ref.current.children[0].clientWidth, clamped: ref.current.clientWidth, idx: 0, cols
          }
        })
      }
    }
    init()
    window.addEventListener('resize', init)
    return () => window.removeEventListener('resize', init)
  }, [modal])

  const startX = useRef()
  const [drag, setDrag] = useState(0)

  const onMove = useCallback((e) => {
    if (e.touches.length > 1) return
    if (!startX.current) {
      startX.current = e.touches[0].screenX
    }
    if (startX.current) setDrag(e.touches[0].screenX)
  }, [])
  const onRelease = useCallback((e) => {
    setDrag((d) => {
      if (d - startX.current > 50) setState({ scrollLeft: true })
      if (startX.current - d > 50) setState({ scrollRight: true })
      return 0
    })
    setTimeout(() => { startX.current = 0 }, 100)
  }, [])
  return (
    <Container>
      {state.left && <ArrowContainer direction="left" onClick={() => setState({ scrollLeft: true })}><Arrow /></ArrowContainer>}
      {state.right && <ArrowContainer direction="right" onClick={() => setState({ scrollRight: true })}><Arrow /></ArrowContainer>}
      <Clamper ref={ref}>
        <TableEl {...props} x={-state.x} onTouchMove={onMove} onTouchEnd={onRelease} />
      </Clamper>
    </Container>
  )
}
