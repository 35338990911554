import React from 'react'
import styled from 'styled-components'
import { compose, pick, map } from 'ramda'
import Grid from '@material-ui/core/Grid'
import Link from 'components/Link'
import StoryblokComponent from 'components/StoryblokComponent'
import { Container } from 'components/RemarkRenderer'
import { color, mediaQuery } from 'utils/style'

function parsePrimitive(v) {
  try {
    return JSON.parse(v)
  } catch (_) {
    return v
  }
}

const Border = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.base.darkGray};
`

const StyledGrid = styled(Grid)`
  ${({ item }) => (item ? 'display:flex; flex-direction:column;' : '')}
  && {
    ${({ root }) => mediaQuery(!!root)}
  }
  ${({ root }) => (root ? 'flex-basis: auto;' : '')}
  background-color: ${color('bg', 'color')};
  color: ${color('bg', 'contrast')};
  && {
    ${({ margin }) => (margin ? 'border: 8px solid white' : '')};
  }
  ${Container} {
    a {
      color: ${color('bg', 'highlight')};
      text-decoration: ${({ theme, bg }) =>
        theme.colors[bg] &&
        theme.colors[bg].highlight === theme.colors[bg].contrast
          ? 'underline'
          : 'inherit'};
    }
  }
  h1,
  h2,
  h3 {
    color: ${color('bg', 'highlight')};
  }
  h4,
  h5,
  h6 {
    color: ${color('bg', 'contrast')};
  }
  .title,
  .roofline,
  .subline {
    color: ${color('bg', 'contrast')};
  }
`

export function extractGroup(obj, keys) {
  return compose(
    map(v => (v === '' ? false : v)),
    map(parsePrimitive),
    pick(keys)
  )(obj)
}

export default function StoryblokGrid({ data, inheritBg, ...props }) {
  const {
    alignContent,
    alignItems,
    direction,
    justify,
    spacing,
    wrap,
    container,
    item,
    body,
    sizes,
    color,
    margin,
    link,
    enableLink,
    border,
  } = data

  const cleanSizes = extractGroup(sizes || data, ['xs', 'sm', 'md', 'lg', 'xl'])

  const containerProps = {
    alignContent,
    alignItems,
    direction,
    justify,
    spacing: spacing ? Number(spacing) : 4,
    // spacing: spacing == null ? 1 : spacing,
    // spacing: Number(spacing),
    wrap,
  }

  const itemProps = {
    ...cleanSizes,
  }

  const BodyContainer = enableLink ? Link : React.Fragment

  const CondBorder = border && !container ? Border : React.Fragment

  return (
    <StyledGrid
      bg={color || inheritBg}
      container={container}
      item={item}
      margin={margin}
      {...(container && containerProps)}
      {...(item && itemProps)}
      {...props}
    >
      <BodyContainer
        {...(enableLink && {
          link,
          productModalType: data.extendedProductModalLink,
          productModalGroup: data.extendedProductModalGroup,
        })}
      >
        <CondBorder>
          {body?.length ? (
            body.map(b => (
              <StoryblokComponent
                key={b.id}
                component={b}
                inheritBg={color || inheritBg}
              />
            ))
          ) : (
            <div />
          )}
        </CondBorder>
      </BodyContainer>
    </StyledGrid>
  )
}
