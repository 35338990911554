// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-edit-js": () => import("./../../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-workaround-page-js": () => import("./../../../src/pages/workaroundPage.js" /* webpackChunkName: "component---src-pages-workaround-page-js" */),
  "component---src-templates-storyblok-default-page-js": () => import("./../../../src/templates/storyblok/default-page.js" /* webpackChunkName: "component---src-templates-storyblok-default-page-js" */),
  "component---src-templates-storyblok-standalone-js": () => import("./../../../src/templates/storyblok/standalone.js" /* webpackChunkName: "component---src-templates-storyblok-standalone-js" */)
}

