import React from 'react'
import styled from 'styled-components'
import { Container as RemarkContainer } from 'components/RemarkRenderer'
import { color, mediaQuery } from 'utils/style'
import Container from 'components/Container'
import Image from '../Image'
import StoryblokComponent from '../StoryblokComponent'

const GridWrapper = styled.div`
  position: relative;
  z-index: 501;
  ${mediaQuery()}
`
const GridItem = styled.div`
  position: absolute;
  background-color: ${color('bg', 'color')};
  color: ${color('bg', 'contrast')};
  ${RemarkContainer} {
    a {
      color: ${color('bg', 'highlight')};
    }
  }
  h1,
  h2,
  h3 {
    color: ${color('bg', 'highlight')};
  }
  h4,
  h5,
  h6 {
    color: ${color('bg', 'contrast')};
  }
  .title,
  .roofline,
  .subline {
    color: ${color('bg', 'contrast')};
  }
  button {
    background-color: ${color('bg', 'button')};
  }
  overflow: ${({ overflow }) => (overflow ? 'visible' : 'hidden')};
  ${({ bgHoverEffect }) =>
    (bgHoverEffect
      ? `
  > *:first-child {
    transition: transform 0.3s ease-in-out;
  }
  :hover > *:first-child {
    transform: scale(1.1);
  }`
      : '')}
  ${mediaQuery()}
`

export default function StoryblokTiles ({ data, inheritBg, ...props }) {
  const margin = 5
  const getPaddingByDevice = (device) => {
    const layout = data.layout.layouts[device]
    const unit = 100 / layout.cols
    return `padding-bottom: ${unit
      * layout.items.reduce((acc, item) => Math.max(acc, item.y + item.h), 0)}%;`
  }
  const tileMediaQuery = idx => (device) => {
    const layout = data.layout.layouts[device]
    const unit = 100 / layout.cols
    const item = layout.items[idx]
    return item
      ? `left: calc(${item.x * unit}% + ${margin}px);
            margin-top: calc(${item.y * unit}% + ${margin}px);
            width: calc(${item.w * unit}% - ${2 * margin}px);
            padding-bottom: calc(${unit * item.h}% - ${2 * margin}px);`
      : 'display:none;'
  }
  const dimensionsByDevice = idx => (device) => {
    const layout = data.layout.layouts[device]
    const item = layout.items[idx]
    return { w: item?.w, h: item?.h, ratio: item ? item.w / item.h : 1 }
  }
  return (
    <Container extraWide={data.extraWide} bg={data.color} {...props}>
      <GridWrapper media={getPaddingByDevice}>
        {data.tiles?.reduce((a, v) => [v, ...a], []).map(
          (tile, idx) =>
            (
              <GridItem
                key={tile.id}
                bg={tile.backgroundColor}
                media={tileMediaQuery(data.tiles.length - 1 - idx)}
                overflow={tile.content?.[0]?.component === 'tile-rich-text'}
                bgHoverEffect={
                  tile.content?.[0]?.component === 'tile-overlay-text'
                }
              >
                <Image
                  background
                  src={tile.backgroundImage}
                  alt={tile.imageAlt}
                  title={tile.imageTitle}
                  description={tile.imageDescription}
                />
                {tile.content?.map(content => (
                  <StoryblokComponent
                    key={content.id}
                    component={content}
                    hasBgImage={!!tile.backgroundImage?.url}
                    inheritBg={tile.backgroundColor || data.color || inheritBg}
                  />
                ))}
              </GridItem>
            )
        )}
      </GridWrapper>
    </Container>
  )
}
