import React from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
`

export default function tileRichText ({ data, inheritBg }) {
  return (
    <Wrapper justify={data.alignment}>
      {data.title && <div className="title">{data.title}</div>}
      <RemarkRenderer
        tile
        background={inheritBg}
        flexFill={{ alignItems: data.alignment }}
        clamp={data.clamp !== false}
        ast={data.content.childMarkdownRemark.htmlAst}
      />
    </Wrapper>
  )
}
