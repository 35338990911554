import Cookies from 'js-cookie'
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo
} from 'react'

const Context = createContext(null)

export default function useCountryCode () {
  return useContext(Context)
}

async function fetchIp () {
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const result = await response.json()
    return result.ip
  } catch (err) {
    console.debug('DEBUG catch error', { err })
    return null
  }
}

async function fetchCountry (ip) {
  try {
    const response = await fetch(
      `http://api.ipstack.com/${ip}?access_key=1e622250bc0aa996eb3684d7f1aa5065&format=1`
    )
    const result = await response.json()
    return result.country_code
  } catch (err) {
    console.debug('DEBUG catch error', { err })
    return null
  }
}

function useCookies () {
  return useMemo(
    () => [Cookies.get('_ga'), Cookies.get('_gid')].some(Boolean),
    []
  )
}

export function CountryProvider ({ children }) {
  const [countryCode, setCountryCode] = useState(null)
  const cookies = useCookies()

  useEffect(() => {
    if (!cookies) return setCountryCode('DE')

    async function fetchCountryCode () {
      try {
        const ip = await fetchIp()
        const country = await fetchCountry(ip)
        setCountryCode(country)
      } catch (err) {
        console.error('error while fetching country code', err.message)
      }
    }
    fetchCountryCode()
  }, [])

  return (
    <Context.Provider
      value={{
        countryCode
      }}
    >
      {children}
    </Context.Provider>
  )
}
