import React, {
  useContext, useState, useReducer, useEffect
} from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/icons/Menu'
import Link from 'components/Link'
import Image from 'components/Image'
import NavContext from 'components/NavProvider'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import LanguageSelect from './LanguageSelect'

const noPropagation = (e) => {
  e.stopPropagation()
}

const ArrowRight = styled.div`
  margin-left: 1rem;
  height: 0;
  width: 0;
  border-left: 0.6rem solid;
  border-bottom: 0.4rem solid transparent;
  border-top: 0.4rem solid transparent;
  ${({ theme }) => mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `position: absolute;
      top: 50%;
      right: 16px;
      height: 1.3rem;
      width: 1.3rem;
      border-right: 2px solid ${theme.colors.base.orange};
      border-bottom: 2px solid ${theme.colors.base.orange};
      border-left: none;
      border-top: none;
      margin-left: 0;
      transform: translateY(-50%) rotate(-45deg);
      transition: transform 0.3s ease-in-out;`
        : '')
  })}
`

const Back = styled.div`
  color: white;
  display: none;
  padding: 16px 0 16px 32px;
  width: calc(100% - 16px);
  position: relative;
  font-weight: normal;
  font-size: 20px;
  ${mediaQuery()({ tablet: 'display: block;' })}
`

const ArrowLeft = styled.div`
    height: 1rem;
    width: 1rem;
    border-left: 2px solid ${({ theme }) => theme.colors.base.orange};
    border-bottom: 2px solid ${({ theme }) => theme.colors.base.orange};
    transform: translateY(-50%) rotate(45deg);
    transition: transform 0.3s ease-in-out;
    display: none;
  ${mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `position: absolute;
      top: 50%;
      left: 16px;
      display: block;
      `
        : '')
  })}
`

const logoQuery = device =>
  (device === 'tablet'
    ? 'height: 68px; width: 120px; z-index: 10000;'
    : '')

const Backdrop = styled.div`
  top: 0;
  left: 0;
  z-index: 900;
  && {
    width: 100vw;
    max-width: unset;
    height: 100vh;
  }
  position: fixed;
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  ${mediaQuery()({
    media: device => (device === 'tablet' ? 'display:initial;' : '')
  })}
`

const containerQuery = device =>
  (device === 'max'
    ? 'width: calc(50vw + 720px);'
    : device === 'desktop'
      ? `width: calc(50vw + 540px);
       max-width: 100%;`
      : device === 'tablet'
        ? 'max-width: 100%; width: calc(50vw + 360px);'
        : 'width: 100%;')

const Container = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  && {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
  }
`

const Bar = styled.div`
  padding: 0 calc(7.07vw - 2.66rem) 0 calc(7.07vw - 3rem);
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
  && {
    ${mediaQuery()({ tablet: 'overflow: hidden;' })};
    margin-bottom: 0;
  }
`

const Logo = styled.div`
  padding: 0.33rem 3rem;
  display: flex;
  flex: 1;
  height: 100%;
  min-width: 200px;
  ${({ active }) => (active ? mediaQuery()({ tablet: 'z-index: 10000; filter: brightness(0) invert(1);', max: '' }) : '')}
  a {
    width: 100%;
    height: 100%;
  }
  img {
    object-position: left !important;
  }
`

const navQuery = device =>
  (device === 'tablet'
    ? `
      min-width: 50vw;
      height: 100vh;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: 0;
      padding: 80px 0 0 0;
      box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;
      z-index: 9999;
      justify-content: flex-start;`
    : device === 'mobile'
      ? 'width: 80vw;'
      : '')

const Nav = styled.div`
  transition: transform 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @media (max-width: 1080px) {
    background-color: ${({ theme }) => theme.colors.base.blue};
    transform: ${({ active }) => (active ? 'translateX(0);' : 'translateX(-120%);')};
  }
  ${mediaQuery()}
`

const navWrapperQuery = device =>
  (device === 'tablet'
    ? `
   position: relative;
   flex-grow: 0;
   > svg {
     display: unset;
   }`
    : '')

const MobileNavWrapper = styled.div`
  > svg {
    color: ${({ theme }) => theme.colors.base.blue};
    height: 72px;
    width: 72px;
    transform: ${({ active }) => (active ? 'scale(1.3)' : 'scaleY(1.3)')};
    padding-right: 32px;
    z-index: 10000;
    display: none;
  }
  flex: 1 0 auto;
  ${mediaQuery()}
`

const CloseButton = styled(Close)`
  && {
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1500;
    ${mediaQuery()({
    media: device =>
      (device === 'tablet' || device === 'mobile'
        ? 'display: initial;'
        : 'display: none;')
  })}
  }
`

const Panel = styled.div`
  border: 20px solid transparent;
  background-clip: content-box;
  margin: -20px;
  font-weight: normal;
  align-items: stretch;
  display: inline-flex;
  flex-direction: row;
  writing-mode: tb-lr;
  writing-mode: vertical-lr;
  max-height: ${({ row }) => `calc(100vh - ${3.5 * row}rem - 190px)`};
  height: ${({ length }) => `${3.5 * length}rem`};
  box-sizing: content-box;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  position:absolute;
  z-index: 950;
  -ms-flex-direction: column;
  ${({ theme }) => mediaQuery()({
    tablet: `
  overflow: auto;
  border: none;
  margin: 0;
  font-weight: bold;
  align-items: initial;
  display: flex;
  writing-mode: initial;
  width: 100%;
  height: calc(100vh - 80px);
  max-height: unset;
  flex-direction: column;
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  padding: 80px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px;
  color: white;
  z-index: 9999;
  background-color: ${theme.colors.base.blue};
  justify-content: flex-start;
  align-items: flex-end;`
  })}
  color: ${({ theme }) => theme.colors.navigation.color};
  :hover {
    color: ${({ theme }) => theme.colors.navigation.inactive};
  }
`

const NavItem = styled.div`
  height: 3.5rem;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  padding: 1rem;
  :hover {
    color: ${({ theme }) => theme.colors.navigation.highlight};
    > ${Panel} {
      display: inline-flex;
    }
  }
  > ${Panel} {
    display: none;
    left: 100%;
    top: 0;
  }
  ${({ theme }) => mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `margin: 0;
          padding: 16px 0 16px 16px;
          border-right: 0;
          width: calc(100% - 16px);
          background-color: ${theme.colors.base.blue};
          color: white;
          height: auto;
          font-size: 20px;
          :last-child {
            border-bottom: none;
          }
          > ${ArrowRight} { display:unset; }
          > a {
              margin-right: 30px;
              width: calc(100% - 30px);
          }
          > svg { display: none; }
          :hover {
            color: white;
            > ${Panel} {
              display: none;
            }
          }`
        : '')
  })}
  @media (max-width: 1080px) {
    ${({ active, theme }) =>
    (active
      ? `
        > ${Panel} {
              display: flex !important;
            }`
      : '')}
  }
`

const NavItemLabel = styled.div`
  text-transform: uppercase;
  ${({ long }) => (long ? 'white-space: normal; width: 250px;' : '')}
  ${mediaQuery()({ tablet: 'white-space: initial;' })}
`

const NavRoot = styled.div`
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: bold;
  height: fit-content;
  cursor: default;
  white-space: nowrap;
  padding: 0 1rem;
  margin: 0.5rem 1rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.base.lightGray};
  color: ${({ theme }) => theme.colors.navigation.color};
  a {
    color: inherit;
  }
  :hover {
    > ${Panel} {
      display: flex;
    }
    color: ${({ theme }) => theme.colors.navigation.highlight};
  }
  > ${ArrowRight} {
    display: none;
  }
  ${({ theme }) => mediaQuery()({
    media: device =>
      (device === 'tablet'
        ? `margin: 0;
          padding: 16px 0 16px 16px;
          border-right: 0;
          width: calc(100% - 16px);
          background-color: ${theme.colors.base.blue};
          color: white;
          font-size: 20px;
          :last-child {
            border-bottom: none;
          }
          img { 
            filter: brightness(0) invert(1);
          }
          > ${ArrowRight} { display:unset; }
          > a {
              margin-right: 30px;
          }
          > svg { display: none; }
          :hover {
            color: white;
            > ${Panel} {
              display: none;
            }
          }`
        : '')
  })}
  :last-child {
    > ${Panel} {
      left: auto;
      right: 0;
      > ${NavItem} {
        justify-content: flex-end;
      }
    }
  }
  > svg {
    height: 20px;
    margin: 0 0.5rem -0.2em 0;
    color: ${({ theme }) => theme.colors.navigation?.color};
  }
  > ${Panel} {
    display: ${({ active }) => (active ? 'flex' : 'none')};
    background-clip: content-box;
    margin-top: 0;
    border-top: 1.5rem solid transparent;
    ${mediaQuery()({
    media: device =>
      (device === 'tablet' ? 'margin:0;border-top: none;' : '')
  })}
    top: 1rem;
    left: 0;
  }
  ${({ active }) =>
    (active
      ? `> ${Panel} {
              display: flex !important;
          }`
      : '')}
`

function NavigationPanel ({
  items, row = 0, backLabel = 'back', onBack
}) {
  const [active, setActive] = useState({})
  const onTouchStart = id => (e) => {
    e.stopPropagation()
    e.preventDefault()
    setActive({ ...active, [id]: !active[id] })
  }
  return (
    <Panel row={row} length={items.length}>
      <Back onTouchEnd={onBack}><ArrowLeft />{backLabel}</Back>
      {items.map((item, idx) => (
        <NavItem
          key={item.id}
          active={active[item.id]}
          onTouchEnd={onTouchStart(item.id)}
          /* {...!item.link && { onTouchEnd: onTouchStart(item.id) }} */
        >
          <Link
            link={item.link}
            {...(item.link && { onTouchEnd: noPropagation })}
          >
            <NavItemLabel long={item.label.length > 25}>{item.label}</NavItemLabel>
          </Link>
          {item.items?.filter(i => !i.hidden).length ? (
            <>
              <ArrowRight onClick={onTouchStart(item.id)} />
              <NavigationPanel
                row={row + idx}
                items={item.items.filter(i => !i.hidden)}
                backLabel={backLabel}
                onBack={onTouchStart(item.id)}
              />
            </>
          ) : (
            []
          )}
        </NavItem>
      ))}
    </Panel>
  )
}

export default function Navigation ({ data }) {
  const [active, setActive] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  )
  const { history } = useContext(NavContext)
  const onTouchStart = (id, propagation) => (e) => {
    if (!propagation) {
      e.stopPropagation()
      e.preventDefault()
    }
    setActive({ [id]: !active[id] })
  }
  useEffect(() => {
    setActive({ root: false })
  }, [history])
  const Icon = active.root ? Close : Menu
  return (
    <>
      {active.root && <Backdrop onClick={onTouchStart('root')} />}
      <Container media={containerQuery}>
        <Bar>
          <Logo className="logo" active={active.root} media={logoQuery}>
            <Link link={data.link}>
              <Image
                critical
                alt="logo"
                src={data.logo}
              />
            </Link>
          </Logo>
          <MobileNavWrapper
            media={navWrapperQuery}
            onTouchMove={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onClick={onTouchStart('root', true)}
            active={active.root}
          >
            <Icon onClick={onTouchStart('root')} />
            <Nav media={navQuery} active={active.root}>
              <Back onTouchEnd={onTouchStart('root')}><ArrowLeft />{data.backLabel || 'back'}</Back>
              {data.items
                .filter(item => !item.hidden)
                .map((item, idx) => (
                  <NavRoot
                    key={item.id}
                    active={active[item.id]}
                    onTouchEnd={onTouchStart(item.id)}
                    /* {...!item.link && { onTouchEnd: onTouchStart(item.id) }} */
                  >
                    <Link
                      link={item.link}
                      {...(item.link && { onTouchEnd: noPropagation })}
                    >
                      {!item.icon?.localFile?.childImageSharp && item.label} {item.icon?.localFile?.childImageSharp && (
                      <Image
                        src={item.icon}
                        style={{
                          height: '40px', width: '40px', marginBottom: '-10px', padding: '10px'
                        }}
                      />
)}
                    </Link>
                    {item.items?.filter(item => !item.hidden).length > 0 ? (
                      <>
                        <ArrowRight onClick={onTouchStart(item.id)} />
                        <NavigationPanel
                          items={item.items.filter(item => !item.hidden)}
                          backLabel={data.backLabel}
                          onBack={onTouchStart(item.id)}
                        />
                      </>
                    ) : (
                      []
                    )}
                  </NavRoot>
                ))}
            </Nav>
          </MobileNavWrapper>
        </Bar>
      </Container>
      {data.showWelcomeOverlay && (
        <LanguageSelect
          roofline={data.welcomeRoofline}
          headline={data.welcomeHeadline}
          subline={data.welcomeSubline}
          navigation={data.welcomeNavigation}
          locator={data.locatorLink}
          internationalLabel={data.welcomeInternationalButtonLabel}
          locatorLabel={data.welcomeLocatorButtonLabel}
        />
      )}
    </>
  )
}
