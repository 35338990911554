import styled from 'styled-components'
import { mediaQuery } from 'utils/style'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`
