import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Image from 'components/Image'
import StoryblokImage from 'components/StoryblokImage'
import RemarkRenderer from 'components/RemarkRenderer'
import styled from 'styled-components'
import { pick } from 'ramda'

const Item = styled.div`
  padding: 52px;
  margin: 16px;
  height: calc(100% - 32px);
  border: 1px solid ${({ theme }) => theme.colors.base.gray};
`

const Title = styled.h2`
  && {
    margin: 4rem 1rem;
    color: ${({ theme }) => theme.colors.base.black};
  }
`

function productModal ({ product, theme }) {
  const items = useMemo(() => {
    if (!product?.component) return []
    const result = [['productSpecs', ''], ['usp', '1'], ['usp', '2'], ['usp', '3'], ['usp', '4']].filter(datum => product.component[datum.join('Title')])
    if (result.length % 2) result[result.length - 1].push(true)
    return result
  }, [product])

  return (
    <>
      <Image
        style={{ height: '61.8vh' }}
        src={product?.component?.image}
        alt={product?.component?.imageAlt}
        title={product?.component?.imageTitle}
      />
      {/* <h4 style={{ margin: 0 }}>{product?.component?.Roofline}</h4> */}
      <Title>{product?.component?.description2Title}</Title>
      {/* <h4 style={{ marginBottom: 32 }}>{product?.component?.subTitle}</h4> */}
      {/* <RemarkRenderer
        ast={product?.component?.description2?.childMarkdownRemark?.htmlAst}
        style={{ paddingBottom: 10 }}
      /> */}
      <Grid container>
        {product?.component?.productSpecsTitle && (
          items.map(([pre, suff, full]) => (
            <Grid item xs={12} md={full ? 12 : 6}>
              <Item>
                <StoryblokImage
                  data={{
                    image: product.component[`${pre}Image${suff}`],
                    imageAlt: product.component[`${pre}Image${suff}Alt`],
                    imageTitle: product.component[`${pre}Image${suff}Title`],
                    squared: true,
                    padding: true
                  }}
                  style={{ width: '100px', paddingBottom: '116px' }}
                />
                <h4>{product.component[`${pre}Title${suff}`]}</h4>
                <RemarkRenderer
                  ast={
                    product.component[`${pre}Text${suff}`]?.childMarkdownRemark
                    ?.htmlAst
                }
                />
              </Item>
            </Grid>
          )))}
      </Grid>
    </>
  )
}
export default withTheme(productModal)
