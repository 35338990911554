import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import Subgroup from 'components/StoryblokModalSubgroup'
import ProductGroupTabs from './ProductGroupTabs'

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.base.black};
  width: 100%;
`

export default function ProductPropertyModal ({ product, ...props }) {
  const [state, setState] = useState(props)

  const { subgroups, groups, setGroup } = useMemo(() => {
    const _subgroups = product?.component?.properties
    return {
      subgroups: _subgroups,
      groups: _subgroups?.reduce(
        (acc, subgroup) =>
          (acc.find(g => g.id === subgroup?.component?.group?.[0]?.id)
            ? acc
            : [...acc, subgroup?.component?.group[0]]),
        []
      ),
      setGroup: g => () => setState(prev => ({ ...prev, group: g.id }))
    }
  }, [product])

  const group = useMemo(() => groups.find(g => g.id === state.group) || groups[0], [groups, state.group])


  return (
    <Container>
      <ProductGroupTabs groups={groups} group={group} setGroup={setGroup} />
      {subgroups
        .filter(sg => sg.component?.group?.[0]?.id === group.id)
        .map(sg => (
          <Subgroup key={sg.id} data={{ subgroup: [sg] }} />
        ))}
    </Container>
  )
}
