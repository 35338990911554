import { createGlobalStyle } from 'styled-components'
import { Container } from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'
import { baseColors } from './theme'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    text-overflow: ellipsis;
  }
  .remark-table {
    tr {
      ${mediaQuery()({
    media: device =>
      (device === 'mobile'
        ? 'float: left; width: 100%; margin-bottom: 4em;'
        : '')
  })}
    }
    thead {
      ${mediaQuery()({
    media: device => (device === 'mobile' ? 'display:none;' : '')
  })}
    }
    td {
      ${mediaQuery()({
    media: device =>
      (device === 'mobile'
        ? `display: flex; float: left; width: 100%; padding: 1em;
        :before {
          content: attr(data-label);
          word-wrap: break-word;
          float: left;
          padding: 1em;
          font-weight: bold;
          margin: -1em 1em -1em -1em;
        }`
        : '')
  })}
    }
  }
  body {
    font-family: Verdana,  "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: ${baseColors.white};
    margin: 0;
    color: ${baseColors.gray};
    overflow-x: hidden;
  }
  #___gatsby {
  }
  ${Container} a {
    color: ${baseColors.blue};
    text-decoration: underline;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  h1 {
    font-size: 36px;
    color: ${({ theme }) => theme.colors.highlight};
    font-weight: bold;
    text-transform: uppercase;
  }
  h2 {
    font-size: 26px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
    text-transform: uppercase;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.highlight};
  }
  h4 {
    font-size: 20px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color};
  }
  h6 {
    font-size: 18px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color};
  }
  blockquote {
    margin: 0;
    p {
      font-size: 26px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    alignSelf: left;
    color: ${({ theme }) => theme.colors.highlight};
  }
  .roofline {
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.color}
  }
  .subline {
    marin-bottom: 28px;
    font-size: 18px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color}
  }
  p, li {
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    margin: 0;
    word-break: break-word;
    > strong {
      font-weight: bold;
      font-size: 17px;
    }
  }
  button {
    font-weight: light;
    font-size: 16px;
    min-height: 1.5em;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
    line-height: 1em;
  }
  :focus {
    outline: none;
  }
`
