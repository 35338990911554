/**
 *
 * StoryblokComponents
 *
 */

import React, { Component } from 'react'
import StoryblokComponent from 'components/StoryblokComponent'

const colors = {
  StoryblokConfigurator: 'black',
  StoryblokProductHighlightCarousel: 'gray',
  StoryblokCarousel: 'none'
}

class StoryblokComponents extends Component {
  componentsLoaded = {}

  static defaultProps = {
    components: []
  }

  onStoryblokComponentLoad = (id) => {
    const { components, onLoaded } = this.props
    this.componentsLoaded[id] = true
    const allComponentsLoaded = components.every(
      blok => this.componentsLoaded[blok.id]
    )
    if (allComponentsLoaded && onLoaded) onLoaded()
  }

  render () {
    const { components, onLoaded, ...rest } = this.props

    return components?.map((component, idx) => (
      <StoryblokComponent
        key={component.id}
        component={component}
        idx={idx}
        next={components[idx - 1]?.color || colors[components[idx - 1]?.__typename] || 'white'}
        previous={components[idx + 1]?.color || colors[components[idx + 1]?.__typename] || 'white'}
        onLoad={() => this.onStoryblokComponentLoad(component.id)}
        onError={() => this.onStoryblokComponentLoad(component.id)}
        {...rest}
      />
    ))
  }
}

export default StoryblokComponents
