import React, { useContext } from 'react'
import { ModalContext } from 'components/Modal'
import styled from 'styled-components'

const Container = styled.div`
  && {
    && {
      padding-bottom: 0;
    }
  }
`

export default function storyblokTileProductTitle ({ data }) {
  const modalContext = useContext(ModalContext)
  const HTag = modalContext != null ? 'h2' : 'h1'
  return (
    <Container>
      <div className="roofline">{data.roofline}</div>
      <HTag>{data.title}</HTag>
      <div className="subline">{data.subline}</div>
    </Container>
  )
}
