import React, {
  useRef, useEffect, useReducer, useCallback, useState
} from 'react'
import styled from 'styled-components'

const Groups = styled.div`
  display: flex;
  width: auto;
  flex: 1 0 auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-${({ x }) => x}px);
`

const Clamper = styled.div`
    width: 100%;
    border-bottom: 1px solid black;
    overflow: hidden;
    position: relative;
    display: flex;
`

const Item = styled.div`
  padding: 32px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  color: ${({ theme, active }) => theme.colors.base.darkGray};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  flex: 0 0 auto;
  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.colors.base.orange};
    color: white;
  }
`

const Arrow = styled.div`  
    height: 1rem;
    width: 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.base.orange};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
`

const ArrowContainer = styled.div`
  z-index:1;
  background: ${({ theme }) => theme.colors.base.lightGray};
  padding: 0 8px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items:center;
  ${({ direction }) => (direction)}: 0px;
  transition: transform 0.3s ease-in-out;
  ${Arrow} {
      transform: rotate(${({ direction }) => (direction === 'left' ? 45 : -135)}deg)
  }
`

export default function ProductGroupTabs ({ groups, group, setGroup }) {
  const ref = useRef()
  const [state, setState] = useReducer((s, action) => {
    const result = { ...s, ...action.init }
    if (action.scrollRight && result.right) result.idx += 1
    if (action.scrollLeft && result.left) result.idx -= 1
    result.left = result.idx > 0
    result.x = result.cols.reduce((acc, val, i) => ((i < result.idx) ? acc + val : acc), 0)
    result.right = result.x < result.total - result.clamped
    if (!result.right) result.x = result.total - result.clamped
    return result
  }, { left: false, right: false })

  useEffect(() => {
    const init = () => {
      if (ref.current.children[0].clientWidth > ref.current.clientWidth) {
        const ths = ref.current.children[0].children
        const cols = []
        for (const th of ths) {
          cols.push(th.clientWidth)
        }
        setState({
          init: {
            total: ref.current.children[0].clientWidth, clamped: ref.current.clientWidth, idx: 0, cols
          }
        })
      }
    }
    init()
    window.addEventListener('resize', init)
    return () => window.removeEventListener('resize', init)
  }, [])

  const startX = useRef()
  const [drag, setDrag] = useState(0)

  const onMove = useCallback((e) => {
    if (e.touches.length > 1) return
    if (!startX.current) {
      startX.current = e.touches[0].screenX
    }
    if (startX.current) setDrag(e.touches[0].screenX)
  }, [])
  const onRelease = useCallback((e) => {
    setDrag((d) => {
      if (d - startX.current > 50) setState({ scrollLeft: true })
      if (startX.current - d > 50) setState({ scrollRight: true })
      return 0
    })
    setTimeout(() => { startX.current = 0 }, 100)
  }, [])

  return (
    <Clamper ref={ref}>
      {state.left && <ArrowContainer direction="left" onClick={() => setState({ scrollLeft: true })}><Arrow /></ArrowContainer>}
      {state.right && <ArrowContainer direction="right" onClick={() => setState({ scrollRight: true })}><Arrow /></ArrowContainer>}
      <Groups x={state.x} onTouchMove={onMove} onTouchEnd={onRelease}>
        {groups.map(g => (
          <Item active={g.id === group.id} onClick={setGroup(g)}>
            {g.component.title}
          </Item>
        ))}
      </Groups>
    </Clamper>
  )
}
