import React, { useContext } from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import Image from 'components/Image'
import { ModalContext } from 'components/Modal'
import { color } from 'utils/style'

const ImageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  ${({ padding }) => (padding ? 'padding: 16px;' : '')}
  transition: transform 0.3s ease-in-out;
`

const Content = styled.div`
  &&& {
    h5, h6,
    p {
      color: inherit;
      font-size: 24px;
      text-transform: uppercase;
      padding-right: 1em;
    }
  }
  margin: 32px;
  position: relative;
  :after {
    content: ' ';
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    margin-left: -1em;
    left: 100%;
    bottom: 0;
    transform: translateY(-50%) rotate(45deg);
    transition: margin-left 0.3s ease-in-out;
  }
`

const Container = styled.div`
  ${({ hasBgImage }) =>
    (hasBgImage
      ? 'background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.17) 75%, rgba(0, 0, 0, 0.72) 90%, rgba(0, 0, 0, 0.92) 100%);'
      : '')}
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  ${({ noOverlap }) => (noOverlap ? `flex-direction: column; align-items: flex-start;
  ${ImageWrapper} {
    position: unset;
  } ` : '')}
  :hover {
    ${Content}:after {
      margin-left: 0;
    }
    ${ImageWrapper} {
      transform: scale(1.1);
    }
  }
`

export default function StoryblokTileOverlayText ({
  data,
  hasBgImage,
  linkProps
}) {
  const modal = useContext(ModalContext)
  const Title = modal != null ? 'h6' : 'h5'
  return (
    <Container
      as={Link}
      noOverlap={data.noOverlap}
      hasBgImage={hasBgImage}
      {...linkProps || {
        gtmEvent: data.gtmEvent,
        link: data.link,
        productModalType: data.extendedProductModalLink,
        productModalGroup: data.extendedProductModalGroup
      }}
    >
      <ImageWrapper padding={data.imagePadding}>
        <Image
          absolute
          src={data.image}
          alt={data.imageAlt}
          title={data.imageTitle}
          description={data.imageDescription}
          imageStyle={{
            objectPosition: `${data.extendedAlignmentHorizontal} ${
              data.extendedAlignmentVertical
            } !important` // todoj: remove !important
          }}
        />
      </ImageWrapper>

      <Content>
        <Title>{data.title}</Title>
        {data.subtitle && <Title>{data.subtitle}</Title>}
      </Content>
    </Container>
  )
}
