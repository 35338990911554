import React from 'react'
import styled, { withTheme } from 'styled-components'
import Image from 'components/Image'

const Container = styled.div`
  position: relative;
  ${({ squared }) => (squared ? 'height: 0; padding-bottom: 100%;' : '')}
  ${({ hover }) => (hover ? `
  .gatsby-image-wrapper {
    transition: transform 0.3s ease-in-out;
  }
  :hover {
    .gatsby-image-wrapper {
      transform: scale(1.05);
    }
  }
  ` : '')}
`

function StoryblokImage ({ data, theme, style }) {
  return (
    <Container squared={data.squared} style={style} hover={data.hover}>
      <Image
        style={{
          backgroundColor: theme.colors[data.backgroundColor]?.color,
          ...(data.squared && { position: 'absolute', height: 0, paddingBottom: '100%' })
        }}
        imageStyle={{ padding: data.padding ? '5px' : 0 }}
        absolute={data.squared}
        src={data.image}
        alt={data.alt}
        title={data.title}
        description={data.description}
      />
    </Container>
  )
}

export default withTheme(StoryblokImage)
