import React, {
  useCallback, useState, useEffect, Fragment, useContext
} from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Close from '@material-ui/icons/Close'
import { mediaQuery } from 'utils/style'
import NavContext from 'components/NavProvider'
import ProductHighlightModal from './ProductHighlightModal'
import ProductAccessoryModal from './ProductAccessoryModal'
import ProductPropertyModal from './ProductPropertyModal'
import ProductModal from './ProductModal'
import CustomModal from './CustomModal'

const StyledModal = styled(Modal)`
  overflow-x: hidden;
  overflow-y: auto;
`

const Backdrop = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  min-height: 100vh;
  ${mediaQuery()({
    max: 'padding: 64px calc(50vw - 820px);', desktop: 'padding: 64px calc(50vw - 540px);', tablet: 'padding: 40px;', mobile: 'padding: 8px;'
  })}
`
const Wrapper = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.base.black};
    font-size: 2.5rem;
    font-weight: bold;
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.base.blue};
    text-transform: uppercase;
  }
  h4 {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.base.blue};
  }
  h5 {
    font-size: 25px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.base.blue};
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.base.blue};
  }
  position: relative;
  border: 1px solid black;
  background: linear-gradient(${({ theme }) => [theme.colors.base.lightGray, theme.colors.base.lighterGray].join(', ')});
  border-radius: 0;
  padding: 40px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin: -40px;
`

const CloseButton = styled(Close)`
  font-size: 32px;
  position: fixed;
  top: 56px;
  z-index: 1500;
  ${mediaQuery()({
    max: 'right: calc(50vw - 804px);',
    desktop: 'right: calc(50vw - 524px);',
    tablet: 'right: 16px; top: 16px;'
  })}
`

const stopPropagation = e => e.stopPropagation()

export const ModalContext = React.createContext()

export default function ModalProvider ({
  open,
  onRequestClose,
  product,
  slug,
  type,
  props,
  children
}) {
  const [modal, setModal] = useState({})
  const { history } = useContext(NavContext) || {}
  useEffect(() => setModal(prev => ({ ...prev, open: false })), [history?.length])
  const loadProductModal = useCallback((_product, _type, _props, _open) => {
    setModal({
      open: _open,
      unmount: ['highlight', 'accessory', 'property'].includes(_type),
      props: { product: _product, ..._props },
      C:
        {
          highlight: ProductHighlightModal,
          accessory: ProductAccessoryModal,
          property: ProductPropertyModal
        }[_type] || ProductModal
    })
  }, [])

  const loadModal = useCallback(async (_slug, _type, _props, _open) => {
    try {
      const content = await import(`../../../.temp/${_slug}.json`)
      const prefetchData = await _props?.prefetch?.()

      if (content?.default?.component?.__typename === 'StoryblokProduct') {
        loadProductModal(content.default, _type, _props, _open)
      } else {
        setModal({
          open: _open,
          unmount: content.default?.component?.unmount,
          props: {
            ..._props,
            ...prefetchData,
            formData: { ..._props?.formData, ...prefetchData?.formData },
            data: content.default
          },
          C: CustomModal
        })
      }
    } catch (e) {
      console.log(_slug, e)
    }
  }, [])

  useEffect(
    () => {
      if (product) {
        loadProductModal(product, type, props, open)
      } else if (slug) {
        loadModal(slug, type, props, open)
      } else if (children) {
        setModal({ open, C: Fragment })
      }
    },
    [product, slug, type, props, open]
  )

  return (
    <ModalContext.Provider value={{ ...modal.props, onRequestClose }}>
      <StyledModal
        open={modal.open}
        hideBackdrop
        keepMounted={!modal.unmount}
        onClose={onRequestClose}
      >
        <Backdrop onClick={onRequestClose}>
          <Wrapper onClick={stopPropagation} onTouchStart={stopPropagation} onTouchEnd={stopPropagation}>
            <CloseButton onClick={onRequestClose} />
            {children}
            {modal.C && <modal.C {...modal.props} />}
          </Wrapper>
        </Backdrop>
      </StyledModal>
    </ModalContext.Provider>
  )
}
