/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { ProductContext } from 'components/ProductContext'
import { FirebaseProvider } from 'components/FirebaseProvider'
import ThemeProvider from 'components/ThemeProvider'
import { CountryProvider } from 'components/CountryProvider'
import { NavProvider } from 'components/NavProvider'
import Navigation from 'components/Navigation'
import Breadcrumbs from 'components/Breadcrumbs'
import Footer from 'components/Footer'
import Layout from 'components/Layout'
import GlobalStyle from 'utils/globalStyle'
import Cookies from 'js-cookie'
// import 'isomorphic-fetch'

window.gaOptout = () => {
  Cookies.set('gtm-disable-ga', true, { expires: 3653 })
}

export const wrapRootElement = ({ element }) => (
  <FirebaseProvider>
    <CountryProvider>
      <ProductContext>{element}</ProductContext>
    </CountryProvider>
  </FirebaseProvider>
)

export const wrapPageElement = ({ element, props }) => {
  if (
    props.pageResources.page.componentChunkName
    === 'component---src-pages-edit-js'
  ) { return element }

  const {
    data: { page, navigation, footer },
    pageContext: { navMap, isCrawford, isStandalone },
    location
  } = props

  return (
    <NavProvider
      map={navMap}
      lang={page.lang}
      location={location}
      context={{
        _product: page.component.contextProduct?.[0],
        _downloadQuery: page.component.downloadQuery,
        ...page.component.context?.reduce(
          (acc, { name, value }) => ({ ...acc, [name]: value }),
          {}
        )
      }}
    >
      <ThemeProvider
        colorTheme={page.component.colorTheme || 'green'}
        isCrawford={isCrawford}
      >
        <>
          <GlobalStyle />
          <Layout>
            {!isStandalone && (
              <Navigation
                data={navigation.component}
                showWelcomeOverlay={page.component.showWelcomeOverlay}
              />
            )}
            {element}
            {!isStandalone && (
              <>
                <Footer data={footer.component} />
                <Breadcrumbs
                  slug={location.pathname}
                  navigation={navigation.component}
                />
              </>
            )}
          </Layout>
        </>
      </ThemeProvider>
    </NavProvider>
  )
}
