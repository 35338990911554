import styled from 'styled-components'
import { mediaQuery, color } from 'utils/style'
import { Container } from 'components/RemarkRenderer'

export default styled.div`
  flex-basis: auto;
  background-color: ${color('bg', 'color')};
  ${Container} {
    a {
      color: ${color('bg', 'highlight')};
      text-decoration: ${({ theme, bg }) => ((theme.colors[bg] && (theme.colors[bg].highlight === theme.colors[bg].contrast)) ? 'underline' : 'inherit')};
    }
  }
  color: ${color('bg', 'contrast')};
  h1,
  h2,
  h3 {
    color: ${color('bg', 'highlight')};
  }
  h4,
  h5,
  h6 {
    color: ${color('bg', 'contrast')};
  }
  .title,
  .roofline,
  .subline {
    color: ${color('bg', 'contrast')};
  }
  ${({ root }) => mediaQuery(!!root)}
  ${({ root, idx }) => (root ? `margin-top:0; margin-bottom: 0; z-index: ${idx+1};` : '')}
  ${({ root, overflow }) => (root && !overflow ? 'overflow-x:hidden;' : '')}
`
