import React, { useState } from 'react'
import styled from 'styled-components'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Item = styled.div`
  flex: 1;
  margin: 32px;
  position: relative;
  && > h6 {
    margin: 10px 0 0 0;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.base.darkGray}
  }
`

const Subgroup = styled.div`
  padding: 32px;
  border-bottom: 1px solid black;
  &:last-child {
    border-bottom: none;
  }
  > h6 {
    font-weight: bold;
    text-transform: uppercase;
  }
`

const GalleryContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  ${mediaQuery()({
    media: device => (device === 'mobile' ? 'flex-direction:column;' : '')
  })}
`
const Accessory = styled.div`
  padding-left: 1rem;
  flex: 1;
  > h6 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.base.darkGray};
  }
`

const Accessories = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  &:after {
    content: "";
    flex: auto;
  }
  > * {
    width: 200px;
    flex: 0 0 auto;
  }
`

export default function ModalSubgroup ({ data }) {
  const [state, setState] = useState({})

  const subgroup = data.subgroup[0]

  const setAccessory = key => value => setState({ ...state, [key]: value })

  const childName =
    subgroup?.component?.__typename === 'StoryblokProductAccessorySubgroup'
      ? 'accessories'
      : 'properties'

  return (
    <Subgroup>
      <h6>{subgroup?.component?.title}</h6>
      <RemarkRenderer
        ast={subgroup?.component?.description?.childMarkdownRemark?.htmlAst}
      />
      {subgroup?.component?.layout === 'gallery' ? (
        <GalleryContainer>
          <Carousel
            media={device =>
              (device === 'mobile' || data.fullWidth
                ? 'width: 100%;'
                : 'width: 50%; height: fit-content; max-width: 450px;')
            }
            height="fit-content"
            squared
            gallery
            infinite
            hideBackside
            onSlide={setAccessory(subgroup.id)}
          >
            {subgroup?.component?.[childName]?.map(accessory => (
              <Image
                title={accessory.component.imageTitle}
                src={accessory.component.image}
                alt={accessory.component.imageAlt}
                description={accessory.component.imageDescription}
              />
            ))}
          </Carousel>
          <Accessory>
            <h6>
              {
                subgroup?.component?.[childName]?.[state[subgroup.id] || 0]
                  ?.component?.title
              }
            </h6>
            <RemarkRenderer
              ast={
                subgroup?.component?.[childName]?.[state[subgroup.id] || 0]
                  ?.component?.description?.childMarkdownRemark?.htmlAst
              }
            />
          </Accessory>
        </GalleryContainer>
      ) : (
        <Accessories>
          {subgroup?.component?.[childName]?.map(accessory => (
            <Item>
              <Image
                flex
                row
                title={accessory.component.imageTitle}
                src={accessory.component.image}
                alt={accessory.component.imageAlt}
                description={accessory.component.imageDescription}
                style={{
                  width: '200px', height: '125px', position: 'relative', objectFit: childName === 'accessories' ? 'contain !important' : 'cover'
                }}
                imageStyle={{ objectFit: childName === 'accessories' ? 'contain !important' : 'cover' }}
                background
              />
              <h6>{accessory.component.title}</h6>
              <RemarkRenderer
                ast={accessory.component.description?.childMarkdownRemark?.htmlAst}
              />
            </Item>
          ))}
        </Accessories>
      )}
    </Subgroup>
  )
}
