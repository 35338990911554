/**
 *
 * StoryblokComponent
 *
 */

import React, { Component } from 'react'
import { pascal } from 'change-case'
import StoryblokCarousel from 'components/StoryblokCarousel'
import StoryblokGallery from 'components/StoryblokGallery'
import StoryblokGrid from 'components/StoryblokGrid'
import StoryblokImage from 'components/StoryblokImage'
import StoryblokRichText from 'components/StoryblokRichText'
import StoryblokTileCallToAction from 'components/StoryblokTileCallToAction'
import StoryblokTileOverlayText from 'components/StoryblokTileOverlayText'
import StoryblokTileRichText from 'components/StoryblokTileRichText'
import StoryblokTiles from 'components/StoryblokTiles'
import StoryblokTileTitle from 'components/StoryblokTileTitle'
import StoryblokTitle from 'components/StoryblokTitle'
import FallbackComponent from './FallbackComponent'

const components = {
  StoryblokCarousel,
  StoryblokGallery,
  StoryblokGrid,
  StoryblokImage,
  StoryblokRichText,
  StoryblokTileCallToAction,
  StoryblokTileOverlayText,
  StoryblokTileRichText,
  StoryblokTiles,
  StoryblokTileTitle,
  StoryblokTitle
}

class StoryblokComponent extends Component {
  state = {
    component: null,
    isLoading: true,
    error: null
  }

  async componentDidMount () {
    const { component: data, onLoad, onError } = this.props

    const type = data._editable
      ? `Storyblok${pascal(data.component)}`
      : data.__typename

    try {
      let editable
      let component
      if (data._editable) {
        try {
          ({ default: component } = await import(
            /* webpackChunkName: "storyblok-component-" */
            /* webpackMode: "lazy" */
            `components/${type}/editable.js`
          ))
        } catch (e) {
          ({ default: component } = await import(
            /* webpackChunkName: "storyblok-component-" */
            /* webpackMode: "lazy" */
            `components/${type}`
          ))
        }
        ({ default: editable } = await import('storyblok-react'))
      } else {
        ({ default: component } = await import(
          /* webpackChunkName: "storyblok-component-" */
          /* webpackMode: "lazy" */
          `components/${type}`
        ))
      }
      this.setState({
        type,
        component,
        isLoading: false,
        editable
      })
      if (onLoad) onLoad()
    } catch (error) {
      this.setState({ type, error, isLoading: false })
      if (onError) onError(error)
    }
  }

  componentDidCatch (error) {
    this.setState({ error })
  }

  render () {
    const { component, ...props } = this.props
    const {
      type, error, component: C, isLoading, editable: E
    } = this.state
    if (isLoading && !components[component.__typename]) return null
    const SbComponent = C || components[component.__typename]
    if (E) {
      if (error) {
        console.log({ component, error })
        return (
          <E content={component}>
            <FallbackComponent type={type} error={error} />
          </E>
        )
      }
      return (
        <E content={component}>
          <SbComponent data={component} {...props} />
        </E>
      )
    }
    if (error) {
      console.log({ component, error, C })
      return <FallbackComponent type={type} error={error} />
    }
    return <SbComponent data={component} {...props} />
  }
}

StoryblokComponent.propTypes = {}

export default React.memo(StoryblokComponent)
