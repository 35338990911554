/* eslint-disable no-confusing-arrow */
import React, {
  useState, useRef, useEffect, useContext
} from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'
import Button from 'components/Button'
import Link from 'components/Link'
import { mathMod } from 'ramda'
import { ModalContext } from 'components/Modal'
import Container from 'components/Container'

const Slider = virtualize(SwipeableViews)

const Content = styled.div`
  h5,
  h6,
  p {
    color: white;
    font-size: 24px;
    padding-right: 1em;
  }
  margin: 32px;
  position: relative;
  ${'' /* :after {
    content: ' ';
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    margin-left: -1em;
    left: 100%;
    bottom: 0;
    transform: translateY(-50%) rotate(45deg);
    transition: margin-left 0.3s ease-in-out;
  } */}
`

const FullSlideContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  background: ${({ disableOverlay }) =>
    disableOverlay
      ? 'transparent'
      : `linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.17) 67%,
    rgba(0, 0, 0, 0.92) 100%
  )`};
  :hover {
    ${Content}:after {
      margin-left: 0;
    }
  }
`

const IndexContainer = styled.div`
  position: relative;
  margin: 0 64px;
  > :first-child {
    > :first-child {
      padding: 0 calc(50% - 116px);
      overflow-x: visible !important;
    }
    overflow-x: hidden;
  }
`

const Title = styled.div`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 40px;
  text-transform: uppercase;
  white-space: pre;
  font-weight: bold;
  color: ${({ theme, color }) => theme.colors[color]?.highlight};
  ${mediaQuery()({ mobile: 'font-size: 32px;' })}
`

const Titles = styled.div`
  overflow-x: visible;
  position: relative;
  height: 58px;
  ${mediaQuery()({
    max: 'margin: 0 calc(50vw - 770px);',
    desktop: 'margin: 0 calc(50vw - 540px);',
    tablet: 'margin: 0;'
  })}
`

const ImageContainer = styled.div`
  margin-top: 40px;
  position: relative;
  height: 60vh;
  width: calc(50% - 70px);
  ${mediaQuery()({ tablet: 'width: 100%; height: 40vh;' })}
`

const Description = styled.div`
  margin: 140px 80px 0;
  flex: 1;
  position: relative;
  ${({ modal }) =>
    mediaQuery()(
      modal
        ? { max: 'margin-left: 0', tablet: 'margin: 0 40px' }
        : {
          max: 'margin-left: calc(50vw - 770px);',
          desktop: 'margin-left: calc(50vw - 540px);',
          tablet: 'margin: 0 64px;'
        }
    )}
`

const SlideContainer = styled.div`
  display: flex;
  ${mediaQuery()({ tablet: 'flex-direction: column-reverse;' })}
`

const Pagination = styled.div`
  ${({ modal }) => mediaQuery(!modal)};
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  position: absolute;
  top: ${({ hasTitles }) => (hasTitles ? 160 : 80)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  z-index: 1;
  pointer-events: none;
  ${({ hasTitles }) =>
    mediaQuery()({
      tablet: `padding: 0; width: 100%; bottom: 0; right: 0; top: ${
        hasTitles ? 'calc(40vh + 60px)' : '40vh'
      }; left: 0;`
    })}
`
const PaginationIcon = styled.div`
  padding: 15px;
  pointer-events: auto;
  ${({ left }) =>
    left
      ? 'position: absolute; left: -72px; top:50%; transform: translateY(-50%);'
      : ''}
  ${({ right }) =>
    right
      ? 'position: absolute; right: -72px; top:50%; transform: translateY(-50%);'
      : ''}
`

const Left = styled.div`
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.base.orange};
  transform: rotate(-45deg);
  height: 21.2px;
  width: 21.2px;
`

const Right = styled.div`
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.base.orange};
  transform: rotate(-45deg);
  height: 21.2px;
  width: 21.2px;
`

function Slide ({
  data, fullWidth, primary, inheritBg
}) {
  const modal = useContext(ModalContext)
  const TitleTag = modal != null ? 'h6' : 'h5'
  if (fullWidth) {
    return (
      <FullSlideContainer disableOverlay={data.disableOverlay}>
        <Image
          background
          src={data.image}
          title={data.imageTitle}
          alt={data.imageAlt}
          objectPosition={data.objectPosition}
          imageStyle={`object-position: ${data.objectPosition};`}
          style={{ zIndex: -1 }}
        />
        {primary && (
          <Content>
            <TitleTag>{data.title}</TitleTag>
          </Content>
        )}
      </FullSlideContainer>
    )
  }
  return (
    <SlideContainer>
      <Description modal={!!modal}>
        <RemarkRenderer
          background={inheritBg}
          clamp="calc(60vh - 140px)"
          ast={data.description?.childMarkdownRemark?.htmlAst}
        >
          {data.buttonLabel && (
            <Link link={data.link}>
              <Button style={{ marginTop: '1rem' }}>{data.buttonLabel}</Button>
            </Link>
          )}
        </RemarkRenderer>
      </Description>
      <ImageContainer>
        <Image
          background={data.cover}
          src={data.image}
          title={data.imageTitle}
          alt={data.imageAlt}
          objectPosition={data.objectPosition}
          imageStyle={`object-position: ${data.objectPosition};`}
        />
      </ImageContainer>
    </SlideContainer>
  )
}

function TitleSlider ({
  color, index, slides, setIndex
}) {
  const ref = useRef()
  useEffect(
    () => {
      const nodes = ref.current.children
      const sizes = []
      let sum = 0
      for (let i = 0; i < nodes.length; i++) {
        sizes.push(sum)
        sum += nodes[i].clientWidth
      }
      sizes.forEach((size, i) => {
        nodes[i].style.transform = `translateX(${size - sizes[10]}px)`
        nodes[i].style.opacity = i === 10 ? '1' : '0.35'
      })
      return () => {
        const ns = ref.current.children
        for (let i = 2; i < ns.length - 2; i++) {
          ns[i].style.transition =
            'all 350ms cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
        }
      }
    },
    [index]
  )
  return (
    <Titles ref={ref}>
      {Array.from({ length: 20 }).map((_, idx) => (
        <Title
          color={color}
          key={index + idx - 10}
          onClick={() => setIndex(index + idx - 10)}
          active={idx === 10}
        >
          {`${slides[mathMod(index + idx - 10, slides.length)].title} . `}
        </Title>
      ))}
    </Titles>
  )
}

export default function StoryblokGallery ({ data, inheritBg, ...props }) {
  const modal = useContext(ModalContext)
  const [index, setIndex] = useState(0)
  if (!data.slides?.length) return null
  return (
    <Container
      bg={data.color || inheritBg}
      max="overflow-x: hidden; width: 100%; position: relative; display: flex; flex-direction: column;padding-top: 24px;"
      mobile="padding-top:16px;"
      extraWide={data.fullWidth && data.extraWide}
      {...props}
      root={props.root && data.fullWidth}
    >
      {!data.fullWidth && data.slides[0].title && (
        <TitleSlider
          color={data.color || inheritBg}
          index={index}
          setIndex={setIndex}
          slides={data.slides}
        />
      )}
      {!data.fullWidth && (
        <Pagination extraWide modal={!!modal} hasTitles={data.slides[0].title}>
          <PaginationIcon onClick={() => setIndex(index - 1)}>
            <Left />
          </PaginationIcon>
          <PaginationIcon onClick={() => setIndex(index + 1)}>
            <Right />
          </PaginationIcon>
        </Pagination>
      )}
      <Slider
        index={index}
        onChangeIndex={setIndex}
        slideStyle={data.fullWidth ? { height: '60vh' } : {}}
        slideRenderer={({ index: idx, key }) => (
          <Slide
            inheritBg={inheritBg}
            data={data.slides[mathMod(idx, data.slides.length)]}
            key={key}
            fullWidth={data.fullWidth}
            primary
          />
        )}
      />
      {data.fullWidth && (
        <IndexContainer>
          <div>
            <Slider
              slideStyle={{ padding: '16px 32px', height: '100px' }}
              index={index}
              onChangeIndex={setIndex}
              slideRenderer={({ index: idx, key }) => (
                <Slide
                  data={data.slides[mathMod(idx, data.slides.length)]}
                  key={key}
                  fullWidth
                />
              )}
            />
          </div>
          <PaginationIcon left onClick={() => setIndex(index - 1)}>
            <Left />
          </PaginationIcon>
          <PaginationIcon right onClick={() => setIndex(index + 1)}>
            <Right />
          </PaginationIcon>
        </IndexContainer>
      )}
    </Container>
  )
}
