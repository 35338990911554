/**
 *
 * RemarkRenderer
 *
 */

import React, {
  useContext, useState, useEffect, useRef
} from 'react'
import { ModalContext } from 'components/Modal'
import styled from 'styled-components'
import renderAst, { renderModalAst } from './renderAst'

export function hexToRgba (hex, a) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `rgba(${Array.from({ length: 3 }).map((_, idx) => parseInt(result[idx + 1], 16))
    .join(',')}, ${a})`
    : hex
}

const Overlay = styled.div`
  opacity: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
  background: linear-gradient(${({ theme, background }) => `${hexToRgba(theme.colors[background]?.color, 0)} 0%, ${hexToRgba(theme.colors[background]?.color, 0)} 66%, ${hexToRgba(theme.colors[background]?.color, 1)} 90%, ${hexToRgba(theme.colors[background]?.color, 1)} 100%`});
`

const ArrowContainer = styled.div`
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, 50%);
`

const Arrow = styled.div`  
    height: 1rem;
    width: 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.base.orange};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
    transform: rotate(135deg);
    transition: transform 0.3s ease-in-out;
`

const Clamper = styled.div`
  width: 100%;
  ${({ tile, open }) => ((tile) ? `position: absolute;
  padding: 16px;
  top: 0;
  left: 0;
  ${(open ? '' : 'bottom: 0; right: 0;')}` : 'position: relative; box-sizing: content-box;')}
  overflow: hidden;
  background: ${({ theme, background, tile }) => (tile ? theme.colors[background]?.color : '')};
  transition: height 300ms ease-in-out;
  ${({
    openHeight, clamp, open, tile
  }) => (openHeight ? open ? `${Overlay} {
    height: ${openHeight + 15 + ((!!tile) * 30)}px;
  }
  ${clamp === true ? `${ArrowContainer} {
    transform: translate(-50%);
  }` : ''}
  height: ${openHeight + 15 + ((!!tile) * 30)}px` : `${Overlay} {
      opacity: 1;
      height: ${clamp};
    }
    ${ArrowContainer} {
      transform: translate(-50%, -50%);
    }
    ${Arrow} {
      transform: rotate(-45deg);
    }
    height: ${clamp}` : `${Arrow} {display:none;} height: ${(clamp === true || !clamp) ? 'auto' : `${clamp}`}`)};
`

export const RemarkContext = React.createContext()

function RemarkRenderer ({
  trimSingle, ast, flexFill, clamp, tile, style, background = 'lightGray', children, ...props
}) {
  const modal = useContext(ModalContext)
  const [open, setOpen] = useState()
  const [height, setHeight] = useState()
  const [clampHeight, setClampHeight] = useState(clamp)
  const ref = useRef()
  useEffect(() => {
    if (ref.current?.children?.[2] && clamp) {
      const saveHeights = () => {
        // console.log(ref.current.clientHeight, ref.current.children[2].clientHeight)
        if (ref.current.children[2].clientHeight > ref.current.clientHeight) {
          setHeight(ref.current.children[2].clientHeight)
          setClampHeight(`${ref.current.clientHeight}px`)
        }
      }
      const images = ref.current.children[2].querySelectorAll('img')
      for (let i = 0; i < images.length; i++) {
        images[i].addEventListener('load', saveHeights)
      }
      saveHeights()
      window.addEventListener('resize', saveHeights)
      return () => {
        for (let i = 0; i < images.length; i++) {
          try {
            images[i].removeEventListener('load', saveHeights)
          } catch (e) {}
        }
        window.removeEventListener('resize', saveHeights)
      }
    }
  }, [clamp, modal])
  if (!ast || ast.children.length === 0) return <div />
  const render = modal != null ? renderModalAst : renderAst
  if (!clamp) {
    return (
      <RemarkContext.Provider value={background}>
        <Clamper style={style} background={background}>{render(
          trimSingle && ast.children.length === 1
            ? {
              ...ast,
              children: [{ ...ast.children[0], tagName: 'span' }]
            }
            : ast
        )}
          {children}
        </Clamper>
      </RemarkContext.Provider>
    )
  }
  return (
    <RemarkContext.Provider value={background}>
      <Clamper
        style={style}
        clamp={clampHeight}
        open={open}
        ref={ref}
        tile={tile}
        openHeight={height}
        background={background}
      >
        <Overlay background={background} />
        <ArrowContainer onClick={() => setOpen(p => !p)}><Arrow /></ArrowContainer>
        <div>
          {render(
            trimSingle && ast.children.length === 1
              ? {
                ...ast,
                children: [{ ...ast.children[0], tagName: 'span' }]
              }
              : ast
          )}
          {children}
        </div>
      </Clamper>
    </RemarkContext.Provider>
  )
}

export default RemarkRenderer
export const Container = Clamper
