import React, { useState } from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import { mediaQuery } from 'utils/style'
import Image from 'components/Image'

const Icon = styled.div`
  width: 60px;
  height: 60px;
  padding: 18px;
  -ms-flex-preferred-size: 24px;
  flex-shrink: 0;
`

const Label = styled.div`
  padding: 0 18px;
  display: flex;
  height: 100%;
  flex-basis: auto;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
  > input {
    color: black;
  }
`

const Container = styled.div`
  width: 60px;
  height: 60px;
  z-index: 701;
  overflow: visible;
  position: relative;
  margin-bottom: 20px;
  pointer-events: none;
  :last-child {
    margin-bottom: 0;
  }
  ${mediaQuery()({
    tablet: 'width: 100%; display: flex;'
  })}
`

const HoveringLink = styled.div`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.orange};
  position: absolute;
  top: 0;
  left: 100%;
  height: 60px;
  transform: translateX(-60px);
  transition: transform 0.3s ease-in-out;
  -ms-flex-flow: column wrap;
  > ${Icon} {
    filter: brightness(0);
  }
  ${({ hasInput }) =>
    (hasInput
      ? `:focus-within {
    transform: translateX(-100%);
    > ${Icon} {
      filter: brightness(0) invert(1);
    }
    > ${Label} {
      color: white;
    }
  }`
      : '')}
  :hover {
    transform: translateX(-100%);
    > ${Icon} {
      filter: brightness(0) invert(1);
    }
    > ${Label} {
      color: white;
    }
  }
  ${({ open }) =>
    mediaQuery()({
      tablet: `width: 100%; position: initial; transform: ${
        open ? 'translateX(0)' : 'translateX(100%)'
      } !important;`
    })}
`

export const PopperContext = React.createContext()

export default function StoryblokQuickAction ({ data, open }) {
  const [query, setQuery] = useState()
  if (data.disabled) return <div style={{ display: 'none' }} />
  return (
    <Container>
      <HoveringLink
        open={open}
        as={Link}
        hasInput={!!data.inputPlaceholder}
        link={data.link}
        query={query}
      >
        <Icon>
          <Image critical src={data.icon} />
        </Icon>
        <Label>
          {data.label}
          {data.inputPlaceholder && (
            <input
              placeholder={data.inputPlaceholder}
              onKeyUp={(e) => {
                if (e.keyCode == 13) e.target.parentElement.click()
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                e.target.focus()
              }}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}
            />
          )}
        </Label>
      </HoveringLink>
    </Container>
  )
}
