import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import { mediaQuery } from 'utils/style'
import { baseColors } from 'utils/theme'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${baseColors.darkGray};
  font-size: 0.8rem;
  ${mediaQuery(true)({
    media: device => (device === 'mobile' ? 'display: none;' : 'padding-top:0; padding-bottom: 0;')
  })}
  > a {
    white-space: pre;
    color: ${baseColors.darkGray};
    filter: brightness(0.8);
    text-decoration: none;
    :nth-child(n + 2) {
      :before {
        color: ${baseColors.darkGray};
        content: '>';
        padding: 0 5px;
      }
    }
    :last-child {
      filter: none;
      :before {
        filter: brightness(0.8);
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`

const getNextItems = ({ items }) =>
  items?.reduce(
    (acc, item) => [
      ...acc,
      ...(item.slug === '' ? getNextItems(item) : (item && [item]))
    ],
    []
  ) || []

function Breadcrumbs ({ slug, navigation }) {
  return (
    <Container>
      {
        slug
          ?.split('/')
          .filter(Boolean)
          .reduce(
            (acc, value) => {
              const item = acc.items?.find(i => i?.slug === value)
              return {
                items: item ? getNextItems(item) : acc.items,
                result: [
                  ...acc.result,
                  ...(item
                    && item.link && [<Link link={item.link}>{item.label}</Link>] || [])
                ]
              }
            },
            { items: getNextItems(navigation), result: [] }
          ).result
      }
    </Container>
  )
}

export default React.memo(Breadcrumbs)
